<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile(), 'h-auto': loading == false, 'h-screen': loading == true }" class="h-auto">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-6xl lg:text-6xl font-bold">King of the Hill</p>
            </div>
            <div class="hidden lg:block">
            <div class="flex flex-col items-center gap-4 mt-6">
                  <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] lg:h-[80px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ days }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Days</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ hours }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Hours</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ minutes }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Minutes</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]">
                          <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ seconds }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-sm text-[#1294F0] uppercase">Seconds</span>
                      </div>
                  </div>
                  <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                      <span class="text-base text-white text-center uppercase">all bets are counted during the <br> inclusive dates:</span>
                      <span v-if="activeTab == 'current'" class="font-bold text-[#FC9A0E] text-center uppercase">{{ startDate }} - {{ endDate }} 12AM UTC</span>
                      <span v-else class="font-bold text-[#FC9A0E] text-center uppercase">{{ prevStartDate }} - {{ prevEndDate }} 12AM UTC</span>
                  </div>
              </div>
          </div>
          <div class="lg:hidden block my-5">
            <div class="flex flex-col items-center gap-4">
                  <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ days }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Days</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ hours }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Hours</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ minutes }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Minutes</span>
                      </div>
                      <div class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]">
                          <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ seconds }}</span>
                          <span v-else class="font-bold text-4xl text-white">0</span>
                          <div v-if="loading == true" class="mb-2" role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                          </div>
                          <span class="font-bold text-xs text-[#1294F0] uppercase">Seconds</span>
                      </div>
                  </div>
                  <div class="flex flex-col" style="font-family: Oxanium, sans-serif;">
                      <span class="text-base text-white text-center uppercase">all wagers are counted during the <br> inclusive dates:</span>
                      <span v-if="activeTab == 'current'" class="font-bold text-[#FC9A0E] text-center uppercase">{{ startDate }} - {{ endDate }} <br> 12AM UTC</span>
                      <span v-else class="font-bold text-[#FC9A0E] text-center uppercase">{{ prevStartDate }} - {{ prevEndDate }} <br> 12AM UTC</span>
                  </div>
              </div>
          </div>
          <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-8 font-bold" style="font-family: Oxanium, sans-serif;">Loading Leaderboard...</div>
          <div v-if="loading == false" class="hidden md:flex flex-row gap-2 mt-10">
                <button @click="activeTab = 'current'; changeLeaderboard()" v-motion-slide-visible-right :class="{'bg-[#1294F0] border-[#1294F0]': activeTab == 'current'}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-14 relative uppercase clip-path-button-left leaderboard-btn">
                   Current Winners
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <span :class="{'border-[#1294F0]': activeTab == 'current'}" class="absolute inset-y-0 left-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[45deg] z-0 leaderboard-span"></span>
                </button>
                <button @click="activeTab = 'previous'; changeLeaderboard()" v-motion-slide-visible-left :class="{'bg-[#1294F0] border-[#1294F0]': activeTab == 'previous'}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-14 relative uppercase clip-path-button-right leaderboard-btn">
                   Previous Winners
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <span :class="{'border-[#1294F0]': activeTab == 'previous'}" class="absolute inset-y-0 right-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[135deg] z-0 leaderboard-span"></span>
                </button>
              </div>
              <div v-if="loading == false" v-motion-pop-visible class="flex md:hidden flex-col gap-2 mt-10">
                <button @click="activeTab = 'current'; changeLeaderboard()" :class="{'bg-[#1294F0] border-[#1294F0]': activeTab == 'current'}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-14 relative uppercase">
                    Current Winners
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
                <button @click="activeTab = 'previous'; changeLeaderboard()" :class="{'bg-[#1294F0] border-[#1294F0]': activeTab == 'previous'}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-14 relative uppercase">
                    Previous Winners
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
            </div>
            <div v-if="loading == false" class="flex flex-wrap justify-center items-stretch gap-2 md:gap-14 mt-14 md:mt-0">
                <div class="flex flex-col justify-center items-center">
                    <div class="bg-[#222222] w-[300px] h-auto modal-clip-path font-oxanium py-10 px-8">
                        <div class="text-white text-xl text-center font-bold uppercase">HIGHEST ANY SLOTS</div>
                        <div class="bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] p-[1.5px] rounded-xl mt-6">
                            <div class="bg-[#1A1A1A] rounded-xl">
                                <div class="flex flex-col justify-between items-center">
                                    <img src="../assets/gold-crown.png" class="w-[60px]">
                                    <img v-if="slotData.userDetails" :src="'https://cdn.discordapp.com/avatars/' +  slotData.userDetails.discordId  + '/' +  slotData.userDetails.avatar  + '.png'" class="w-[110px] rounded-full"/>
                                    <img v-else src="../assets/avatar-1.png">
                                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                                    <span class="text-[#F09C1E] font-bold">1</span>
                                    </div>
                                    <div>
                                    <span class="text-[#A8A8A8] font-bold text-sm">{{ slotData.username ? slotData.username : '---' }}</span>
                                    </div>
                                </div>
                                <div class="bg-black flex flex-col text-center w-full mt-4">
                                    <span class="text-white font-bold text-xl uppercase py-3">KOTH SLOTS</span>
                                </div>
                                <div class="flex flex-col gap-4 px-4 py-4">
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Bet ID:</p>
                                        <a :href="slotData.betId ? slotData.betId : ''" target="_blank" class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-4 w-28 truncate rounded-lg">
                                            {{ slotData.betId ? slotData.betId : '---' }}
                                        </a>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Multiplier:</p>
                                        <p class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-3 w-28 rounded-lg">
                                            x{{ slotData.multi ? slotData.multi : '---' }}
                                        </p>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Reward:</p>
                                        <p class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-3 w-28 rounded-lg">
                                            $ {{ activeTab == 'current' ? slotCurrentReward[0] : slotPrevReward[0] }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click="slotRules = true" class="text-white bg-gradient-to-r hover:bg-gradient-to-l text-base py-4 relative uppercase text-center cursor-pointer from-[#F09C1E] to-[#FFDD15] w-full">
                        View Rules
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                    </button>
                </div>
                <div class="flex flex-col justify-center items-center py-14">
                    <div class="bg-[#222222] w-[300px] h-auto modal-clip-path font-oxanium py-10 px-8">
                        <div class="text-white text-xl text-center font-bold uppercase">HIGHEST Sports</div>
                        <div class="bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] p-[1.5px] rounded-xl mt-6">
                            <div class="bg-[#1A1A1A] rounded-xl">
                                <div class="flex flex-col justify-between items-center">
                                    <img src="../assets/gold-crown.png" class="w-[60px]">
                                    <img v-if="sportData.userDetails" :src="'https://cdn.discordapp.com/avatars/' +  sportData.userDetails.discordId  + '/' +  sportData.userDetails.avatar  + '.png'" class="w-[110px] rounded-full"/>
                                    <img v-else src="../assets/avatar-2.png">
                                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                                    <span class="text-[#F09C1E] font-bold">1</span>
                                    </div>
                                    <div>
                                        <span class="text-[#A8A8A8] font-bold text-sm">{{ sportData.username ? sportData.username : '---' }}</span>
                                    </div>
                                </div>
                                <div class="bg-black flex flex-col text-center w-full mt-4">
                                    <span class="text-white font-bold text-xl uppercase py-3">KOTH Sports Bet</span>
                                </div>
                                <div class="flex flex-col gap-4 px-4 py-4">
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Bet ID:</p>
                                        <a :href="sportData.betId ? sportData.betId : ''" target="_blank" class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-4 w-28 truncate rounded-lg">
                                            {{ sportData.betId ? sportData.betId : '---' }}
                                        </a>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Multiplier:</p>
                                        <p class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-3 w-28 rounded-lg">
                                            x{{ sportData.betId ? sportData.multi : '---' }}
                                        </p>
                                    </div>
                                    <div class="flex flex-row justify-between items-center">
                                        <p class="text-white text-sm">Reward:</p>
                                        <p class="font-bold text-sm text-center text-black bg-gradient-to-br from-[#8C421D] via-[#FBE67B] to-[#D4A041] py-1 px-3 w-28 rounded-lg">
                                        $ {{ activeTab == 'current' ? sportCurrentReward[0] : sportPrevReward[0] }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click="sportRules = true" class="text-white bg-gradient-to-r hover:bg-gradient-to-l text-base py-4 relative uppercase text-center cursor-pointer from-[#F09C1E] to-[#FFDD15] w-full">
                        View Rules
                        <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                    </button>
                </div>
            </div>
        </div>
        <div v-show="slotRules == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-8 sm:pb-8">
                        <div class="mt-3 text-center sm:mt-0 sm:text-center">
                            <h3 class="text-xl lg:text-3xl font-semibold leading-6 text-white uppercase" id="modal-title">KOTH SLOTS RULES</h3>
                            <ol class="list-decimal flex flex-col text-left items-start text-white mt-4 uppercase text-xs md:text-sm lg:text-base tracking-wider px-2 ml-3">
                                <li>Use the Code "<span class="text-[#1294F0]">BallyBoy</span>".</li>
                                <li>Minimum Bet Requirement <span class="font-thin"> - Submit a Bet ID with at least $0.40 USD.</span>.</li>
                                <li>Achieve a Multi Above 500x <span class="font-thin"> - Only slot wins with 500x or higher are eligible</span>.</li>
                                <li>Must be registered via this link <span class="font-thin"> - SIGN UP HERE</span> <a href="https://metamedialinks.com/e36249d00" target="_blank"><span class="font-thin text-[#1294F0] underline">Registration link</span></a>.</li>
                                <li>Bet over $1.00 will receive additional prize money 💰</li>
                                <li>Submit Your Multi’s Bet ID Link <span class="font-thin"> - ACCESS THE FORM HERE</span> <a href="https://docs.google.com/forms/d/e/1FAIpQLSfjMYg9wYQckEdSYr4ZgfP3oR-DJnpQDOfbX2G6dVcvbYb7mw/viewform" target="_blank"><span class="font-thin text-[#1294F0] underline">Form link</span></a>.</li>
                            </ol>
                        </div>
                        <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                            <button @click="slotRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                            </button>
                        </div>
                        <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                            <button @click="slotRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div v-show="sportRules == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-xl">
                    <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-8 sm:pb-8">
                        <div class="mt-3 text-center sm:mt-0 sm:text-center">
                            <h3 class="text-xl lg:text-3xl font-semibold leading-6 text-white uppercase" id="modal-title">KOTH SPORT BET RULES</h3>
                            <ol class="list-decimal flex flex-col items-start text-left text-white mt-4 uppercase text-xs md:text-sm lg:text-base tracking-wider px-2 ml-3">
                                <li>Registration Link <span class="font-thin"> - Use the code "<span class="text-[#1294F0] font-bold">BallyBoy</span>" on either Metaspins or Dailyspins. Alternatively, <br> you can use this link for both platforms <a href="https://dailyspins.com/?ref=BALLYBOY" target="_blank"><span class="font-thin text-[#1294F0] underline">Dailyspins</span></a> or <a href="https://metaspins.com/?ref=BALLYBOY" target="_blank"><span class="font-thin text-[#1294F0] underline">Metaspins</span></a> </span>.</li>
                                <li>No Minimum Bet Required <span class="font-thin"> - The highest winning bet slip in $ amount wins the $1,000 at the end of the timer.</span>.</li>
                                <li>SPORTS SLIPS <span class="font-thin"> - MUST BE SUBMITTED WITHIN 24 HOURS TO BE ELIGIBLE TO WIN.</span></li>
                                <li>Submission Link <span class="font-thin"> - To submit your hit, please visit the following link</span> <a href="https://docs.google.com/forms/d/e/1FAIpQLSdmwEFHpCi9C_kKZ0fnObYqHbM5PKpNuLzRZpvM_f45WpxBgA/viewform" target="_blank"><span class="font-thin text-[#1294F0] underline">Form link</span></a>.</li>
                            </ol>
                        </div>
                        <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                            <button @click="sportRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                            </button>
                        </div>
                        <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                            <button @click="sportRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
        
    },
    data() {
        return {
            activeTab: 'current',
            
            days: '',
            hours: '',
            minutes: '',
            seconds: '',

            slotData: [],
            sportData: [],

            slotCurrentData: [],
            slotPrevData: [],
            sportsCurrentData: [],
            sportsPrevData: [],

            startDate: null,
            endDate: null,
    
            prevStartDate: null,
            prevEndDate: null,

            notFormattedEndDate: null,
            loading: false,

            slotCurrentReward: JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_CURRENT_PRIZES),
            slotPrevReward: JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_PREV_PRIZES),
            sportCurrentReward: JSON.parse(process.env.VUE_APP_SPORTBET_LEADERBOARD_CURRENT_PRIZES),
            sportPrevReward: JSON.parse(process.env.VUE_APP_SPORTBET_LEADERBOARD_PREV_PRIZES),

            slotRules: false,
            sportRules: false,
        }
    },
    methods: {
        async init() {
            const baseUrl = process.env.VUE_APP_BASE_API;
            this.loading = true
            
            await axios.get(`${baseUrl}/api/leaderboard/slot-challenges`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
                this.slotCurrentData = res.data.leaderboardData.length > 0 ? res.data.leaderboardData[0] : []
        
                this.startDate = moment(res.data.startDate).utc().format('MMMM DD, YYYY')
                this.endDate = moment(res.data.endDate).utc().format('MMMM DD, YYYY')
                
                this.notFormattedEndDate = res.data.endDate
            });

            await axios.get(`${baseUrl}/api/leaderboard/slot-challenges?previous=true`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
                this.slotPrevData = res.data.leaderboardData.length > 0 ? res.data.leaderboardData[0] : []

                this.prevStartDate = moment(res.data.startDate).utc().format('MMMM DD, YYYY')
                this.prevEndDate = moment(res.data.endDate).utc().format('MMMM DD, YYYY')
            });

            await axios.get(`${baseUrl}/api/leaderboard/koth/sportsbet`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
                this.sportsCurrentData = res.data.leaderboardData.length > 0 ? res.data.leaderboardData[0] : []
            });

            await axios.get(`${baseUrl}/api/leaderboard/koth/sportsbet?previous=true`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
                this.sportsPrevData = res.data.leaderboardData.length > 0 ? res.data.leaderboardData[0] : []
            });

            this.changeLeaderboard()
            this.startTimer();

            this.loading = false
        },
        changeLeaderboard(){
        if(this.activeTab == 'current'){
                this.slotData = this.slotCurrentData
                this.sportData = this.sportsCurrentData
            }
            else{
                this.slotData = this.slotPrevData
                this.sportData = this.sportsPrevData
            }
        },
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
            v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        maskString(str) {
            if (!str || str.length < 2) {
            return str;
            }
            if (str.length <= 4) {
            const masked = '*'.repeat(str.length - 1);
            const last = str.slice(-1);
            return `${masked}${last}`;
            } else {
            const masked = '*'.repeat(4);
            const rest = str.slice(4);
            return `${masked}${rest}`;
            }
        },
        getRewardValue(rank) {
            const prizes = JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_CURRENT_PRIZES);
            if(rank >= prizes.length){
                return 'X'
            }else{
                return '$ ' + prizes[rank];
            }
            
        },
        getPrevRewardValue(rank){
            const prizes = JSON.parse(process.env.VUE_APP_SLOT_LEADERBOARD_PREV_PRIZES);
            if(rank >= prizes.length){
                return 'X'
            }else{
                return '$ ' + prizes[rank];
            }
        },
        startTimer() {
        const getNextTargetDate = () => {
            // const now = moment.utc();
    
            let targetDate = moment.utc(this.notFormattedEndDate).subtract(1, 'seconds');
    
            // if (now.isAfter(targetDate)) {
            //   targetDate.add(1, 'week');
            // }
    
            return targetDate;
            };
    
            let targetDate = getNextTargetDate();
            let timerInterval;
    
            const updateTimer = () => {
            const nowUtc = moment.utc();
            const distance = targetDate.diff(nowUtc);
    
            if (distance < 0) {
                // this.init();
                clearInterval(timerInterval);
    
                // targetDate = getNextTargetDate();
                // this.startTimer();

                this.days = 0
                this.hours = 0
                this.minutes = 0
                this.seconds = 0
            } else {
                const duration = moment.duration(distance);
                this.days = Math.floor(duration.asDays());
                this.hours = duration.hours();
                this.minutes = duration.minutes();
                this.seconds = duration.seconds();
            }
            };
    
            updateTimer();
            timerInterval = setInterval(updateTimer, 1000);
        },
        isMobile() {
            return window.innerWidth < 1024;
        },
    },
    mounted() {
      this.init();
    },
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.frame-bg {
  background-image: url('../assets/slot-challenges-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile-frame-bg {
  background-image: url('../assets/slot-challenges-mobile-frame-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.table-bg {
  background-image: url('../assets/slot-challenges-table-bg.png');
  background-repeat: no-repeat;
}

.mobile-table-bg {
  background-image: url('../assets/slot-challenges-mobile-table-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
}

.leaderboard-btn:hover .leaderboard-span {
  border-color: #1294F0;
}

.cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-clip-path {
    clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 100%, 0 100%, 0 10%);
}

.responsive-iframe-container {
    position: relative;
    width: 100%; /* Full width */
    height: 100%;
    padding-top: 90%;
    overflow: hidden;
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>
