<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-auto">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40 font-oxanium">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold">Milestones</p>
            </div>
            <div class="flex justify-center milestone-card-bg border border-white border-opacity-40 rounded-[20px] my-8 lg:my-14 mx-4">
                <div class="flex flex-col p-2 lg:p-20 gap-4">
                    <div class="flex flex-col md:flex-row gap-4">
                        <div v-motion-pop-visible class="bg-[#222222] rounded-lg lg:h-[210px]">
                            <div class="flex flex-col lg:flex-row justify-center md:justify-start lg:items-center gap-3 p-8 md:pr-24 lg:pr-20">
                                <div class="rounded-full max-w-[100px]">
                                    <img v-if="$store.state.user" :src="picUrl" class="rounded-full">
                                    <img v-else src="../assets/profile-default-img.png" class="rounded-full">
                                </div>
                                <div class="flex flex-col gap-1">
                                    <p class="text-white font-bold text-xl">{{ $store.state.user ? displayName : 'Not Logged In' }}</p>
                                    <p class="text-[#F7D14E] text-sm">Metaspins Milestone Member</p>
                                    <div class="flex flex-row gap-1">
                                        <p class="text-white font-bold text-sm">Discord ID:</p>
                                        <p class="text-white font-bold text-sm">{{ $store.state.user ? discordID : '---' }}</p>
                                    </div>
                                    <div class="flex flex-row gap-1">
                                        <p class="text-white font-bold text-sm">Points:</p>
                                        <p class="text-white font-bold text-sm">{{ $store.state.user ? userPoints : '---' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <div class="flex flex-col lg:flex-row gap-4">
                                <div v-motion-pop-visible class="bg-[#222222] rounded-lg h-[110px] px-8 pt-4">
                                    <div class="flex flex-row justify-between items-center gap-10">
                                        <div class="flex flex-col gap-1">
                                            <p class="text-white text-sm tracking-wider">Current Wager</p>
                                            <p class="text-white font-bold text-xl">$ {{ $store.state.user ? totalWagers?.totalWager : '0.00'  }}</p>
                                        </div>
                                        <div>
                                            <img src="../assets/milestone-coin.png">
                                        </div>
                                    </div>
                                </div>
                                <div v-motion-pop-visible class="bg-[#222222] rounded-lg h-[110px] px-8 pt-4">
                                    <div class="flex flex-row justify-between items-center gap-10">
                                        <div class="flex flex-col gap-1">
                                            <p class="text-white text-sm tracking-wider">Next Milestone</p>
                                            <p class="text-white font-bold text-xl">$ {{ $store.state.user ? getNextMilestone(milestones, totalWagers?.totalWager).nextMilestone : '0.00'  }} </p>
                                        </div>
                                        <div>
                                            <img src="../assets/milestone-flag.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-motion-pop-visible class="bg-[#222222] rounded-lg h-[85px] pt-6 px-8">
                                <div class="flex flex-row justify-between">
                                    <p class="text-white text-xs tracking-wider">Progress so far..</p>
                                    <p class="text-white text-xs tracking-wider">{{ $store.state.user ? getNextMilestone(milestones, totalWagers?.totalWager).progressPercentage : '0' }}%</p>
                                </div>
                                
                                <div class="flex w-full h-2.5 rounded-full overflow-hidden bg-gray-200 mt-1" role="progressbar">
                                    <div class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500" :style="{ width: progressBarWidth + '%' }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-[#222222] rounded-lg">
                        <div class="p-4 md:p-8">
                            <div class="flex flex-col md:flex-row justify-center items-center gap-2 md:justify-between">
                                <div class="flex flex-row gap-1 items-center">
                                    <img src="../assets/milestone-target.png">
                                    <p class="text-white font-bold text-xl mt-2">Slot Milestone</p>
                                </div>
                                <div>
                                    <button @click="milestoneRules = true" class="bg-[#1294F0] px-6 py-3 rounded-lg transition duration-200 ease-in-out hover:scale-105">
                                        <div class="flex flex-row items-center gap-2">
                                            <img src="../assets/info.png">
                                            <p class="text-white uppercase font-bold text-sm">about the slot milestone</p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="border-b-[1px] border-gray-400 w-full mt-4"/>
                            <div class="flex flex-row ml-2 lg:ml-6 w-full">
                                <div class="flex flex-col items-center mt-20 lg:mt-12 mb-4">
                                    <!-- Step 1 -->
                                    <div v-motion-pop-visible class="relative flex flex-col justify-center items-center">
                                        <div class="w-2 h-2 bg-transparent border-2 p-3 border-gray-400 rounded-full">
                                            <div class="w-2 h-2 bg-white rounded-full flex -ml-1 -mt-1"></div>
                                        </div>
                                        <div class="w-[2px] h-36 lg:h-16 bg-gray-400"></div>
                                    </div>

                                    <!-- Step 2 -->
                                    <div v-motion-pop-visible class="relative flex flex-col items-center">
                                        <div class="w-2 h-2 bg-transparent border-2 p-3 border-gray-400 rounded-full">
                                            <div class="w-2 h-2 bg-white rounded-full flex -ml-1 -mt-1"></div>
                                        </div>
                                        <div class="w-[2px] h-36 lg:h-16 bg-gray-400"></div>
                                    </div>

                                    <!-- Step 3 -->
                                    <div v-motion-pop-visible class="relative flex flex-col items-center">
                                        <div class="w-2 h-2 bg-transparent border-2 p-3 border-gray-400 rounded-full">
                                            <div class="w-2 h-2 bg-white rounded-full flex -ml-1 -mt-1"></div>
                                        </div>
                                        <div class="w-[2px] h-36 lg:h-16 bg-gray-400"></div>
                                    </div>

                                    <!-- Step 4 -->
                                    <div v-motion-pop-visible class="relative flex flex-col items-center">
                                        <div class="w-2 h-2 bg-transparent border-2 p-3 border-gray-400 rounded-full">
                                            <div class="w-2 h-2 bg-white rounded-full flex -ml-1 -mt-1"></div>
                                        </div>
                                        <div class="w-[2px] h-36 lg:h-16 bg-gray-400"></div>
                                    </div>

                                    <!-- Final Step -->
                                    <div v-motion-pop-visible class="relative flex flex-col items-center">
                                        <div class="w-2 h-2 bg-transparent border-2 p-3 border-gray-400 rounded-full">
                                            <div class="w-2 h-2 bg-white rounded-full flex -ml-1 -mt-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="this.$store.state.user" class="flex flex-col w-full my-8 px-7 gap-[36px]">
                                    <div v-motion-pop-visible v-for="m in milestones.milestones" :key="m" class="flex flex-col lg:flex-row lg:justify-between w-full gap-2 lg:gap-0">
                                        <div class="flex flex-col lg:flex-row justify-start lg:items-center lg:gap-2">
                                            <p class="text-white font-bold text-lg lg:text-xl">$ {{ m.requiredWager }} Wagered</p>
                                            <p class="text-white text-sm tracking-wider">{{ m.userMilestone?.status ? m.userMilestone?.status : 'Not Started'}}</p>
                                        </div>
                                        <div class="flex flex-col lg:flex-row lg:items-center gap-1 lg:gap-3">
                                            <h1 class="bg-gradient-to-l from-[#F09C1E] to-[#FFDD15] inline-block text-transparent bg-clip-text text-xl font-bold">$ {{ m.reward }}</h1>
                                            <a v-if="m.userMilestone?.status == 'Completed'" href="https://discord.com/channels/1261860693339013234/1261860694299639860" target="_blank">
                                            <button class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] py-4 px-5 lg:px-10 relative uppercase">
                                                Claim Now
                                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                            </button>
                                            </a>
                                            <button v-if="m.userMilestone?.status == 'Claimed'" class="text-white bg-gradient-to-r from-[#016D3A] to-[#01D370] py-4 px-5 lg:px-10 relative uppercase">
                                                Claimed ✔
                                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                            </button>
                                            <button v-if="m.userMilestone?.status == 'Not Started' || m.userMilestone?.status == 'In Progress'" class="text-white border border-white py-4 px-5 lg:px-8 relative uppercase">
                                                {{ m.userMilestone?.status ? m.userMilestone?.status : 'Not Started'}}
                                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="flex flex-col w-full my-8 px-7 gap-[36px]">
                                    <div v-motion-pop-visible v-for="m in milestonesArray" :key="m" class="flex flex-col lg:flex-row lg:justify-between w-full gap-2 lg:gap-0">
                                        <div class="flex flex-col lg:flex-row justify-start lg:items-center lg:gap-2">
                                            <p class="text-white font-bold text-lg lg:text-xl">$ {{ m.wager }} Wagered</p>
                                            <p class="text-white text-sm tracking-wider">Not Logged in</p>
                                        </div>
                                        <div class="flex flex-col lg:flex-row lg:items-center gap-1 lg:gap-3">
                                            <h1 class="bg-gradient-to-l from-[#F09C1E] to-[#FFDD15] inline-block text-transparent bg-clip-text text-xl font-bold">$ {{ m.reward }}</h1>
                                            <button class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] py-4 px-5 lg:px-10 relative uppercase">
                                                Login to Claim
                                                <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="milestoneRules == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
            <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-3xl">
                    <div class="cards-frame px-10 pb-4 pt-5 sm:pt-10 sm:px-8 sm:pb-8">
                        <div class="mt-3 text-center sm:mt-0 sm:text-center">
                            <h3 class="text-xl lg:text-3xl font-semibold leading-6 text-white uppercase" id="modal-title">ABOUT THE SLOTS <br>MILESTONES REWARDS</h3>
                            <ol class="list-decimal flex flex-col items-start text-left text-white mt-4 uppercase text-xs md:text-sm lg:text-base tracking-wider px-2 ml-3">
                                <li>How does the Slot Milestone program work? <br> <span class="font-thin"> Every slot bet you place on Metaspins will count towards your Milestone progress.</span></li>
                                <li>What rewards can I earn? <br> <span class="font-thin"> The rewards are based on your wagered amount on slots. The more you wager, the better the rewards.</span></li>
                                <li>How long does processing take? <br> <span class="font-thin"> Processing times may vary, but typically it takes a few days to process.</span></li>
                                <li>How do we prevent abuse? <br> <span class="font-thin"> To ensure fairness and prevent misuse, all claims are thoroughly checked for any signs of abuse. This process helps us maintain the integrity of our Milestone Rewards and ensures that all users have an equal opportunity to redeem their points. </span></li>
                            </ol>
                        </div>
                        <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                            <button @click="milestoneRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-16 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase  main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                            </button>
                        </div>
                        <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                            <button @click="milestoneRules = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                            Close
                            <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
    components: {
    },
    data() {
        return {
            displayName: null,
			picUrl: null,
			discordID: null,
            userPoints: 0,

            milestones: [],
            totalWagers: [],
            progressBarWidth: 0,
            milestoneRules: false,

            milestonesArray: [
                { reward: '50', wager: '10,000' },
                { reward: '250', wager: '100,000' },
                { reward: '1000', wager: '500,000' },
                { reward: '2,500', wager: '1,500,000' },
                { reward: '5,000', wager: '4,000,000' },
            ]
        }
    },
    methods: {
        init() {
			const token = localStorage.getItem('ballyboy-access-token');
			if(token){
				axios.get(process.env.VUE_APP_BASE_API + '/api/user', {
					headers: {
						'x-api-key': process.env.VUE_APP_X_API_KEY,
						'Authorization': `Bearer ${token}`,
					},
				})
				.then((res) => {
					if (res.data) {
						this.displayName = res.data.user.username
						this.picUrl = 'https://cdn.discordapp.com/avatars/' +  res.data.user.discordId  + '/' +  res.data.user.profileImage  + '.png'
						this.discordID = res.data.user.discordId
					}
				}).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-access-token");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
			}
		},
        initUserPoints(){
            if(this.$store.state.user == null) return;

            const token = localStorage.getItem('ballyboy-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/user/points', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.userPoints = res.data.points
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        initMilestone(){
            if(this.$store.state.user == null) return;

            const token = localStorage.getItem('ballyboy-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/user/milestones', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.milestones = res.data.milestones[0]
                    this.totalWagers = res.data.totalWagers[0]
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        getNextMilestone(milestoneData, currentWager) {
            const milestones = milestoneData.milestones;

            if (!milestones || milestones.length === 0) {
                return { nextMilestone: null, progressPercentage: 0, progressBarWidth: "0%" };
            }

            let lastCompletedMilestone = null;
            for (let i = 0; i < milestones.length; i++) {
                if (milestones[i].userMilestone?.status === "Completed") {
                    lastCompletedMilestone = milestones[i];
                }
            }

            let nextMilestone = milestones.find(m => m.userMilestone?.status === "In Progress");
            
            if (!nextMilestone && lastCompletedMilestone) {
                nextMilestone = milestones.find(m => m.index === lastCompletedMilestone.index + 1);
            }

            if (!nextMilestone) {
                return { nextMilestone: null, progressPercentage: 100, progressBarWidth: "100%" };
            }

            const prevWager = lastCompletedMilestone ? lastCompletedMilestone.requiredWager : 0;
            const nextWager = nextMilestone.requiredWager;

            const progress = ((currentWager - prevWager) / (nextWager - prevWager)) * 100;
            const progressBarWidth = `${Math.min(Math.max(progress, 0), 100)}`; // Clamp between 0 and 100
            this.progressBarWidth = parseInt(progressBarWidth).toFixed(0)

            return {
                nextMilestone: nextMilestone.requiredWager,
                progressPercentage: progressBarWidth,
            };
        },
        isMobile() {
            return window.innerWidth < 1024;
        },
    },
    mounted(){
        this.init()
        this.initUserPoints()
        this.initMilestone()
    }
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cards-frame {
  background-image: url('../assets/cards-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.milestone-card-bg {
	background: rgb(252,252,252);
	background: -moz-linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	background: -webkit-linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	background: linear-gradient(148deg, rgba(252,252,252,0.1) 0%, rgba(18,148,240,0.1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcfcfc",endColorstr="#1294f0",GradientType=1);
	padding: 1px;
}
</style>
