<template>
    <section :class="{'hero-bg': !isMobile(), 'hero-bg-mobile': isMobile() }" class="h-auto">
        <div class="flex flex-col justify-center items-center pt-28 md:pt-36 lg:pt-40" style="font-family: Oxanium, sans-serif;">
            <div v-motion-pop-visible class="text-center text-white uppercase">
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold">Slot Bounties</p>
            </div>
            <div v-motion-pop-visible class="hidden lg:block w-[1180px] h-[350px] bounties-frame-bg mt-10">
                <div class="flex flex-row justify-start items-center">
                    <div class="flex flex-col justify-start items-start ml-14 gap-2 w-[70%] mt-8">
                        <div class="flex flex-col gap-3" style="font-family: Oxanium, sans-serif;">
                            <div class="flex flex-row items-center gap-3">
                               <img src="../assets/megaphone.png" class="w-[60px]">
                               <div class="text-4xl font-bold text-white text-left uppercase">
                                  HOW IT WORKS?
                                </div>
                            </div>
                            
                            <ul class="list-decimal text-white text-md font-oxanium uppercase pl-5 space-y-1">
                                <li>Be signed up to <a href="https://metamedialinks.com/e36249d00" target="_blank" class="text-blue-500">Metaspins.com</a> or <a href="https://dailyspins.com/?ref=BALLYBOY" target="_blank" class="text-blue-500">Dailyspins.com</a></li>
                                <li>Hit the Multiplier on the minimum bet required (base bet only)</li>
                                <li>Bonus buys and spins both count as long as your base bet matches or is higher then the bounty requires</li>
                                <li>Get your bet id from your win and a screenshot and join the <a href="https://discord.com/invite/Rh6kTmSJuP" target="_blank" class="text-blue-500">discord</a> and create a ticket to claim your winnings!</li>
                                <li>New bounties reset every week with new games</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block lg:hidden">
                <div class="flex flex-row justify-start items-center my-8">
                    <div class="flex flex-col items-center gap-6">
                        <div class="flex flex-row items-center gap-3">
                            <img src="../assets/megaphone.png" class="w-[60px]">
                            <div class="text-2xl font-bold text-white text-left uppercase">
                              HOW IT WORKS?
                            </div>
                        </div>
                        <div class="flex flex-col gap-2" style="font-family: Oxanium, sans-serif;">
                            <ol class="list-decimal md:list-none text-white text-sm font-oxanium uppercase px-10 lg:pl-5 space-y-1 text-left md:text-center ml-4">
                                <li>Be signed up to <a href="https://metamedialinks.com/e36249d00" target="_blank" class="text-blue-500">Metaspins.com</a> or <a href="https://dailyspins.com/?ref=BALLYBOY" target="_blank" class="text-blue-500">Dailyspins.com</a></li>
                                <li>Hit the Multiplier on the minimum bet required (base bet only)</li>
                                <li>Bonus buys and spins both count as long as your base bet matches or is higher then the bounty requires</li>
                                <li>Get your bet id from your win and a screenshot and join the <a href="https://discord.com/invite/Rh6kTmSJuP" target="_blank" class="text-blue-500">discord</a> and create a ticket to claim your winnings!</li>
                                <li>New bounties reset every week with new games</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden md:flex flex-row gap-2 mt-4">
                <button @click="selectedChallenge = 1" v-motion-pop-visible :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 1}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase clip-path-button-left leaderboard-btn">
                   Metaspins
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <span :class="{'border-[#1294F0]': selectedChallenge == 1}" class="absolute inset-y-0 left-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[44deg] z-0 leaderboard-span"></span>
                </button>
                <!-- <button @click="selectedChallenge = 2" v-motion-pop-visible :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 2}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase leaderboard-btn">
                   Packdraw
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button> -->
                <button @click="selectedChallenge = 4" v-motion-slide-visible-left :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 4}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase clip-path-button-right leaderboard-btn">
                   Empire Drop
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                  <span :class="{'border-[#1294F0]': selectedChallenge == 4}" class="absolute inset-y-0 right-0 -mb-[18px] bg-transparent border-r-2 border-white transform skew-x-[135deg] z-0 leaderboard-span"></span>
                </button>
              </div>
              <div v-motion-pop-visible class="flex md:hidden flex-col gap-2 mt-10">
                <button @click="selectedChallenge = 1" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 1}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                    Metaspins
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
                <!-- <button @click="selectedChallenge = 2" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 2}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                    Packdraw
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button> -->
                <button @click="selectedChallenge = 4" :class="{'bg-[#1294F0] border-[#1294F0]': selectedChallenge == 4}" class="text-white bg-transparent hover:bg-[#1294F0] border border-white hover:border-[#1294F0] font-base py-4 px-20 relative uppercase">
                   Empire Drop
                  <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                </button>
              </div>
        </div>
        <div v-if="selectedChallenge == 1">
            <MetaspinsChallengesSection/>
        </div>
        <div v-if="selectedChallenge == 2">
            <PackdrawChallengesSection/>
        </div>
        <div v-if="selectedChallenge == 3">
            <ChickenGGChallengesSection/>
        </div>
        <div v-if="selectedChallenge == 4">
            <EmpireDropChallengesSection/>
        </div>
    </section>
</template>

<script>
// import ClashGGChallengesSection from '@/sections/ClashGGChallengesSection.vue';
import MetaspinsChallengesSection from '@/sections/MetaspinsChallengesSection.vue';
import PackdrawChallengesSection from '@/sections/PackdrawChallengesSection.vue';
import ChickenGGChallengesSection from '@/sections/ChickenGGChallengesSection.vue';
import { defineComponent } from 'vue'
import EmpireDropChallengesSection from '@/sections/EmpireDropChallengesSection.vue';

export default defineComponent({
    components: {
        MetaspinsChallengesSection,
        PackdrawChallengesSection,
        ChickenGGChallengesSection,
        EmpireDropChallengesSection
    },
    data() {
        return {
            selectedChallenge: 1,
        }
    },
    methods: {
        isMobile() {
            return window.innerWidth < 1024;
        },
    }
})
</script>

<style scoped>
.hero-bg {
  background-image: url("../assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.hero-bg-mobile {
  background-image: url("../assets/hero-bg-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.leaderboard-btn:hover .leaderboard-span {
  border-color: #1294F0;
}
.bounties-frame-bg {
  background-image: url('../assets/bounties-frame-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
