<template>
  <div 
    class="relative group"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <!-- Main Button -->
    <router-link 
      v-if="isRouter"
      :to="to"
      class="relative flex items-center px-4 py-6 text-[11px] 2xl:text-xs duration-300 ease-in-out group"
      :class="{
        'bg-light-gradient bg-no-repeat bg-[length:200%_100%] border-b-2 border-b-[#1689DB]': isActive
      }"
    >
      <span 
        class="absolute inset-0 bg-light-gradient bg-no-repeat bg-[length:200%_100%] transition-all"
        :class="{
          'hidden': isActive, 
          'group-hover:animate-light-sweep': isHovered && !isActive,
          'group-hover:animate-reverse-light-sweep': !isHovered && !isActive,
          'opacity-0': !isHovered && !isActive
        }" 
      ></span>
      <img :src="require(`@/assets/${icon}`)" alt="icon" class="w-4 mb-0.5 mr-2">
      <p class="tracking-widest font-bold">{{ label }}</p>
      
    </router-link>
    <div 
      v-else
      class="relative flex items-center px-5 py-6 text-[11px] 2xl:text-xs duration-300 ease-in-out group cursor-pointer"
      :class="{
        'bg-light-gradient bg-no-repeat bg-[length:200%_100%] border-b-2 border-b-[#1689DB]': isActive
      }"
    >
      <span 
        class="absolute inset-0 bg-light-gradient bg-no-repeat bg-[length:200%_100%] transition-all"
        :class="{
          'hidden': isActive, 
          'group-hover:animate-light-sweep': isHovered && !isActive,
          'group-hover:animate-reverse-light-sweep': !isHovered && !isActive,
          'opacity-0': !isHovered && !isActive
        }" 
      ></span>
      <img :src="require(`@/assets/${icon}`)" alt="icon" class="w-4 mb-0.5 mr-2">
      <p class="tracking-widest font-bold">{{ label }}</p>
      <img class="ml-1" src="../assets/dropdown-arrow.png">
    </div>

    <!-- Dropdown Menu -->
    <div 
      v-if="isDropdown"
      class="absolute left-0 bg-[#373F49] border border-[#3A3F4C] shadow-lg rounded-sm py-2 transition-opacity duration-300 w-[180px]"
      :class="{ 'opacity-100': isHovered, 'opacity-0 pointer-events-none': !isHovered }"
    >
      <ul>
        <li
          v-for="(item, index) in dropdownItems" 
          :key="index" 
          class="px-4 py-2 hover:bg-light-gradient bg-no-repeat bg-[length:200%_100%] hover:text-white text-xs font-bold cursor-pointer"
          @click="navigate(item.to)"
        >
        <img :src="require(`@/assets/${item.icon}`)" alt="icon" class="inline-flex w-4 mb-0.5 mr-2">
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    to: { type: String, required: false }, // Optional for dropdown
    icon: { type: String, required: true },
    label: { type: String, required: true },
    isActive: { type: Boolean, default: false },
    isRouter: { type: Boolean, default: true }, // Determines if it's a router-link
    isDropdown: { type: Boolean, default: false }, // Determines if dropdown is enabled
    dropdownItems: { 
      type: Array, 
      default: () => [] // Example: [{ label: "Option 1", to: "/option1" }]
    }
  },
  data() {
    return {
      isHovered: false,
      subIsHovered: false,
    };
  },
  methods: {
    navigate(to) {
      this.$router.push(to); // Navigate programmatically
    }
  }
};
</script>

<style scoped>
/* Add your custom animation style here if needed */
</style>
