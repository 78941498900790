import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import MainView from '../views/MainView.vue'
import ErrorView from '../views/ErrorView.vue'
import MainPage from '../pages/MainPage.vue'
import StorePage from '../pages/StorePage.vue'
import ProfilePage from '../pages/ProfilePage.vue'
import LeaderboardsPage from '../pages/LeaderboardsPage.vue'
import MetaspinsRegistrationPage from '../pages/MetaspinsRegistrationPage.vue'
import ChallengesPage from '../pages/ChallengesPage.vue'
import GiveawaysPage from '../pages/GiveawaysPage.vue'
import ClashGGRegistrationPage from '../pages/ClashGGRegistrationPage.vue'
import PackdrawRegistrationPage from '../pages/PackdrawRegistrationPage.vue'
import SlotChallengesPage from '../pages/SlotChallengesPage.vue'
import SportsBet from '../pages/SportsBet.vue'
import KothPage from '../pages/KothPage.vue'
import MilestonesPage from '../pages/MilestonesPage.vue'

const routes = [
  {
    path: '/',
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/home',
        alias: '/',
        component: MainPage
      },
      {
        path: '/slot-bounties',
        alias: '/slot-bounties',
        component: ChallengesPage
      },
      {
        path: '/king-of-the-hill',
        alias: '/king-of-the-hill',
        component: KothPage
      },
      {
        path: '/koth-slots',
        alias: '/koth-slots',
        component: SlotChallengesPage
      },
      {
        path: '/koth-sports-bet',
        alias: '/koth-sports-bet',
        component: SportsBet
      },
      {
        path: '/leaderboard',
        alias: '/leaderboards',
        component: LeaderboardsPage
      },
      {
        path: '/milestones',
        alias: '/milestones',
        component: MilestonesPage
      },
      {
        path: '/metaspins-registration',
        alias: '/metaspins-registration',
        component: MetaspinsRegistrationPage
      },
      {
        path: '/clashgg-registration',
        alias: '/clashgg-registration',
        component: ClashGGRegistrationPage
      },
      {
        path: '/packdraw-registration',
        alias: '/packdraw-registration',
        component: PackdrawRegistrationPage
      },
      {
        path: '/giveaways',
        alias: '/giveaways',
        component: GiveawaysPage
      },
      {
        path: '/store',
        alias: '/store',
        component: StorePage
      },
      {
        path: '/profile',
        alias: '/profile',
        component: ProfilePage
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0}
  },
})

router.beforeEach((to, from, next) => {
  if (to.path === '/profile' && store.state.user === null) {
    next('/error-page')
  } else {
    next()
  }
})

export default router
