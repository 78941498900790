<template>
  <!-- Leaderboard Section -->
  <section class="container h-auto mx-auto" id="leaderboard">
    <div class="flex justify-center">
      <div class="flex flex-col items-center justify-center p-8 rounded-3xl">
        <div class="hidden lg:block">
          <div class="flex flex-col items-center gap-4 mt-4">
            <div
              class="flex flex-row gap-4"
              style="font-family: Oxanium, sans-serif"
            >
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[80px] lg:h-[80px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ days }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-sm text-[#1294F0] uppercase"
                  >Days</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ hours }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-sm text-[#1294F0] uppercase"
                  >Hours</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ minutes }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-sm text-[#1294F0] uppercase"
                  >Minutes</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[80px] h-[80px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ seconds }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-sm text-[#1294F0] uppercase"
                  >Seconds</span
                >
              </div>
            </div>
            <div class="flex flex-col" style="font-family: Oxanium, sans-serif">
              <span class="text-base text-center text-white uppercase"
                >all wagers are counted during the <br />
                inclusive dates:</span
              >
              <span
                v-if="activeTab == 'current'"
                class="font-bold text-[#FC9A0E] text-center uppercase"
                >{{ startDate }} - {{ endDate }} 12AM UTC</span
              >
              <span
                v-else
                class="font-bold text-[#FC9A0E] text-center uppercase"
                >{{ prevStartDate }} - {{ prevEndDate }} 12AM UTC</span
              >
            </div>
          </div>
        </div>
        <div class="block lg:hidden">
          <div class="flex flex-col items-center gap-4">
            <div
              class="flex flex-row gap-4"
              style="font-family: Oxanium, sans-serif"
            >
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ days }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-xs text-[#1294F0] uppercase"
                  >Days</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ hours }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-xs text-[#1294F0] uppercase"
                  >Hours</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ minutes }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-xs text-[#1294F0] uppercase"
                  >Minutes</span
                >
              </div>
              <div
                class="bg-[#222222] flex flex-col justify-center items-center w-[65px] h-[65px]"
              >
                <span
                  v-if="activeTab == 'current'"
                  class="text-4xl font-bold text-white"
                  >{{ seconds }}</span
                >
                <span v-else class="text-4xl font-bold text-white">0</span>
                <div v-if="loading == true" class="mb-2" role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                <span class="font-bold text-xs text-[#1294F0] uppercase"
                  >Seconds</span
                >
              </div>
            </div>
            <div class="flex flex-col" style="font-family: Oxanium, sans-serif">
              <span class="text-base text-center text-white uppercase"
                >all wagers are counted during the <br />
                inclusive dates:</span
              >
              <span
                v-if="activeTab == 'current'"
                class="font-bold text-[#FC9A0E] text-center uppercase"
                >{{ startDate }} - {{ endDate }} 12AM UTC</span
              >
              <span
                v-else
                class="font-bold text-[#FC9A0E] text-center uppercase"
                >{{ prevStartDate }} - {{ prevEndDate }} 12AM UTC</span
              >
            </div>
          </div>
        </div>

        <div
          v-motion-pop-visible
          class="hidden lg:block frame-bg w-[1180px] h-[350px] mt-10"
        >
          <div class="flex flex-row items-end justify-evenly">
            <div class="flex flex-col items-start ml-10 gap-2 w-[80%] mt-4">
              <div
                class="flex flex-col gap-2"
                style="font-family: Oxanium, sans-serif"
              >
                <span class="text-3xl font-bold text-left text-white uppercase"
                  >Climb the Leaderboard and Earn Big Rewards!</span
                >
                <span class="text-sm font-bold text-left text-white uppercase"
                  >The Top 8 players on EACH Leaderboard will score valuable
                  Store Points based on their rank:</span
                >
                <ul
                  class="pl-1 space-y-1 text-sm text-white uppercase font-oxanium"
                >
                  <li>
                    🏆<b>1ST</b> Place - <b>$ {{ leaderboardPrizes[0] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🥈<b>2ND</b> Place - <b>$ {{ leaderboardPrizes[1] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🥉<b>3RD</b> Place - <b>$ {{ leaderboardPrizes[2] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🏅<b>4TH</b> Place - <b>$ {{ leaderboardPrizes[3] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🏅<b>5TH</b> Place - <b>$ {{ leaderboardPrizes[4] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🎖️<b>6TH</b> Place - <b>$ {{ leaderboardPrizes[5] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🎖️<b>7TH</b> Place - <b>$ {{ leaderboardPrizes[6] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                  <li>
                    🎖️<b>8TH</b> Place - <b>$ {{ leaderboardPrizes[7] }}</b
                    ><img
                      src="../assets/chicken-token.png"
                      class="inline-flex w-5 h-5 ml-1.5 -mt-1.5"
                    />
                  </li>
                </ul>
                <span class="text-sm font-bold text-left text-white uppercase"
                  >You can SNAG up to 3,000 Store Points in a month! Start
                  climbing and claim your rewards!</span
                >
              </div>
            </div>
            <div
              class="flex flex-col gap-4 mb-4 mr-10"
              style="font-family: Oxanium, sans-serif"
            >
              <a href="https://chicken.gg/r/Ballyboy" target="_blank">
                <button
                  class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] font-bold py-4 w-[280px] relative uppercase transition ease-in-out hover:scale-110 duration-200"
                >
                  Sign up on chicken.gg
                  <span
                    class="absolute inset-x-0 bottom-0 w-24 h-1 mx-auto bg-white"
                  ></span>
                </button>
              </a>
              <router-link
                to="/store"
                class="text-white hover:bg-gradient-to-l from-[#373F49] border border-white font-bold text-base py-4 px-16 relative uppercase text-center transition ease-in-out hover:scale-110 duration-200"
              >
                Go to Store
                <span
                  class="absolute inset-x-0 bottom-0 w-16 h-1 mx-auto bg-white"
                ></span>
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div v-motion-pop-visible class="block px-4 py-5 mt-10 lg:hidden mobile-frame-bg md:px-8">
              <div class="flex flex-col items-start justify-center">
                <div class="flex flex-col items-start gap-2 w-[80%] ">
                      <div class="flex flex-col gap-2" style="font-family: Oxanium, sans-serif;">
                          <span class="text-lg font-bold text-left text-white uppercase">Climb the Leaderboard and Earn Big Rewards!</span>
                          <span class="text-xs font-bold text-left text-white uppercase">The Top 8 players on EACH Leaderboard will score valuable Store Points based on their rank:</span>
                          <ul class="space-y-1 text-xs text-white uppercase font-oxanium">
                              <li>🏆<b>1ST</b> Place - <b>{{ pointsDistribution[0] }}</b> Points</li>
                              <li>🥈<b>2ND</b> Place - <b>{{ pointsDistribution[1] }}</b> Points</li>
                              <li>🥉<b>3RD</b> Place - <b>{{ pointsDistribution[2] }}</b> Points</li>
                              <li>🏅<b>4TH</b> Place - <b>{{ pointsDistribution[3] }}</b> Points</li>
                              <li>🏅<b>5TH</b> Place - <b>{{ pointsDistribution[4] }}</b> Points</li>
                              <li>🎖️<b>6TH</b> Place - <b>{{ pointsDistribution[5] }}</b> Points</li>
                              <li>🎖️<b>7TH</b> Place - <b>{{ pointsDistribution[6] }}</b> Points</li>
                              <li>🎖️<b>8TH</b> Place - <b>{{ pointsDistribution[7] }}</b> Points</li>
                          </ul>
                          <span class="text-xs font-bold text-left text-white uppercase">You can SNAG up to 3,000 Store Points in a month! Start climbing and claim your rewards!</span>
                      </div>
                  </div>
              </div>
              <div class="flex flex-col justify-center gap-4 mt-6" style="font-family: Oxanium, sans-serif;">
                <a href="https://chicken.gg/r/Ballyboy" target="_blank">
                  <button class="text-white bg-gradient-to-r from-[#165381] to-[#1294F0] font-bold py-4 w-full relative uppercase transition ease-in-out hover:scale-105 duration-200">
                    Sign up on chicken.gg
                    <span class="absolute inset-x-0 bottom-0 w-24 h-1 mx-auto bg-white"></span>
                  </button>
                </a>
                <router-link to="/store" class="text-white hover:bg-gradient-to-l from-[#373F49] border border-white font-bold text-base py-4 px-16 relative uppercase text-center transition ease-in-out hover:scale-105 duration-200">
                  Go to Store
                  <span class="absolute inset-x-0 bottom-0 w-16 h-1 mx-auto bg-white"></span>
                </router-link>
              </div>
          </div> -->
        <div
          v-if="loading == true"
          class="mt-8 text-2xl font-bold text-center text-white md:text-4xl"
          style="font-family: Oxanium, sans-serif"
        >
          Loading Leaderboard...
        </div>
        <div v-if="loading == false" class="container hidden mx-auto md:block">
          <div
            class="flex flex-row items-center justify-center gap-6 lg:gap-44 mt-60"
            style="font-family: Oxanium, sans-serif"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-20 w-60"
            >
              <div
                class="bg-[#222222] w-full lg:w-[320px] h-[280px] rounded-t-3xl"
              >
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/silver-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[1] != undefined &&
                        top3[1].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[1].userDetails.discordId +
                        '/' +
                        top3[1].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-2.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#FFFFFF] font-bold">2</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[1] != undefined
                          ? maskString(top3[1].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[1] != undefined
                          ? top3[1].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[1] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#838383] via-[#DDDDC4] to-[#838383] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(1)
                          : getPrevRewardValue(1)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center -mt-40 w-60"
            >
              <div
                class="bg-[#222222] w-full lg:w-[320px] h-[280px] rounded-t-3xl"
              >
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/gold-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[0] != undefined &&
                        top3[0].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[0].userDetails.discordId +
                        '/' +
                        top3[0].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-1.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#F09C1E] font-bold">1</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[0] != undefined
                          ? maskString(top3[0].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[0] != undefined
                          ? top3[0].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[0] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#8C421D] via-[#FBE67B] to-[#8C421D] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(0)
                          : getPrevRewardValue(0)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-20 w-60"
            >
              <div
                class="bg-[#222222] w-full lg:w-[320px] h-[280px] rounded-t-3xl"
              >
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/bronze-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[2] != undefined &&
                        top3[2].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[2].userDetails.discordId +
                        '/' +
                        top3[2].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-3.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#C26D5A] font-bold">3</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[2] != undefined
                          ? maskString(top3[2].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[2] != undefined
                          ? top3[2].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[2] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#A1503D] via-[#CA7561] to-[#A1503D] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(2)
                          : getPrevRewardValue(2)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-center space-x-8 border-b border-gray-600 mt-14"
            style="font-family: Oxanium, sans-serif"
          >
            <div
              class="relative pb-2 text-white cursor-pointer"
              :class="{ 'text-blue-500': activeTab === 'current' }"
              @click="
                activeTab = 'current';
                changeLeaderboard();
              "
            >
              CURRENT LEADERBOARD
              <span
                v-if="activeTab === 'current'"
                class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
              ></span>
            </div>

            <div
              class="relative pb-2 text-white cursor-pointer"
              :class="{ 'text-blue-500': activeTab === 'previous' }"
              @click="
                activeTab = 'previous';
                changeLeaderboard();
              "
            >
              PREVIOUS LEADERBOARD
              <span
                v-if="activeTab === 'previous'"
                class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
              ></span>
            </div>
          </div>
          <div
            class="flex justify-center mt-8 mb:mb-24"
            style="font-family: Oxanium, sans-serif"
          >
            <table class="w-full">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:pl-[40px] lg:pl-28 md:py-10 text-white text-[18px] md:text-xl font-bold text-left"
                  >
                    Ranks
                  </th>
                  <th
                    class="px-1 md:py-10 text-white text-[18px] md:text-xl font-bold text-center"
                  >
                    User
                  </th>
                  <th
                    class="px-1 md:py-10 text-white text-[18px] md:text-xl font-bold text-center"
                  >
                    Amount
                  </th>
                  <th
                    class="px-1 md:pr-[40px] lg:pr-28 md:py-10 text-white text-[18px] md:text-xl font-bold text-right"
                  >
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="top5.length == 0">
                  <td colspan="4" class="py-4 font-bold text-center text-white">
                    No data to show
                  </td>
                </tr>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in top5"
                  :key="index"
                >
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white text-left"
                  >
                    {{ getOrdinalSuffix(index + 4) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white text-center"
                  >
                    <div class="flex flex-row items-center justify-start gap-2">
                      <img
                        v-if="item.userDetails"
                        :src="
                          'https://cdn.discordapp.com/avatars/' +
                          item.userDetails.discordId +
                          '/' +
                          item.userDetails.avatar +
                          '.png'
                        "
                        class="w-6 rounded-full"
                      />
                      <img
                        v-else
                        src="../assets/avatar-1.png"
                        class="w-6 rounded-full"
                      />
                      {{ maskString(item.displayName) }}
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white text-center"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img
                        src="../assets/gold-coin.png"
                        class="w-6 mb-0.5 mr-1"
                      />
                      <span>{{ item.wagerAmount.toFixed(2) }}</span>
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white text-right"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img
                        src="../assets/gold-coin.png"
                        class="w-6 mb-0.5 mr-1"
                      />
                      <span>{{
                        activeTab == "current"
                          ? getRewardValue(index + 3)
                          : getPrevRewardValue(index + 3)
                      }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false" class="block md:hidden">
          <div
            class="flex flex-col items-center justify-center gap-4 font-oxanium"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-40 w-60"
            >
              <div class="bg-[#222222] w-full h-[280px] rounded-t-3xl">
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/gold-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[0] != undefined &&
                        top3[0].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[0].userDetails.discordId +
                        '/' +
                        top3[0].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-1.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#F09C1E] font-bold">1</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[0] != undefined
                          ? maskString(top3[0].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[0] != undefined
                          ? top3[0].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[0] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#8C421D] via-[#FBE67B] to-[#8C421D] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(0)
                          : getPrevRewardValue(0)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-40 w-60"
            >
              <div class="bg-[#222222] w-full h-[280px] rounded-t-3xl">
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/silver-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[1] != undefined &&
                        top3[1].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[1].userDetails.discordId +
                        '/' +
                        top3[1].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-2.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#FFFFFF] font-bold">2</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[1] != undefined
                          ? maskString(top3[1].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[1] != undefined
                          ? top3[1].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[1] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#838383] via-[#DDDDC4] to-[#838383] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(1)
                          : getPrevRewardValue(1)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-40 w-60"
            >
              <div class="bg-[#222222] w-full h-[280px] rounded-t-3xl">
                <div class="flex flex-col items-center justify-center">
                  <div
                    class="flex flex-col items-center justify-between -mt-28"
                  >
                    <img src="../assets/bronze-crown.png" class="w-[60px]" />
                    <img
                      v-if="
                        top3.length > 0 &&
                        top3[2] != undefined &&
                        top3[2].userDetails != undefined
                      "
                      :src="
                        'https://cdn.discordapp.com/avatars/' +
                        top3[2].userDetails.discordId +
                        '/' +
                        top3[2].userDetails.avatar +
                        '.png'
                      "
                      class="w-[110px] rounded-full"
                    />
                    <img v-else src="../assets/avatar-3.png" />
                    <div class="bg-black px-2 py-[1px] rounded-full -mt-3">
                      <span class="text-[#C26D5A] font-bold">3</span>
                    </div>
                    <div>
                      <span class="text-[#A8A8A8] font-bold text-sm">{{
                        top3.length > 0 && top3[2] != undefined
                          ? maskString(top3[2].displayName)
                          : "---"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mt-6 text-center bg-black">
                    <span class="text-sm font-bold text-white uppercase"
                      >Wagered</span
                    >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-10 mb-1" />
                      <span class="text-4xl font-bold text-white uppercase">{{
                        top3.length > 0 && top3[2] != undefined
                          ? top3[2].wagerAmount.toFixed(2)
                          : "---"
                      }}</span>
                    </div>
                    <!-- <span class="text-sm font-bold text-white uppercase">{{ pointsDistribution[2] }} Points</span> -->
                  </div>
                  <div
                    class="bg-gradient-to-tr from-[#A1503D] via-[#CA7561] to-[#A1503D] flex flex-col text-center w-full mt-16 py-2"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img src="../assets/gold-coin.png" class="w-12 mb-1" />
                      <span class="text-4xl font-bold text-black uppercase">{{
                        activeTab == "current"
                          ? getRewardValue(2)
                          : getPrevRewardValue(2)
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-center space-x-8 border-b border-gray-600 mt-14"
            style="font-family: Oxanium, sans-serif"
          >
            <div
              class="relative pb-2 text-center text-white cursor-pointer"
              :class="{ 'text-blue-500': activeTab === 'current' }"
              @click="
                activeTab = 'current';
                changeLeaderboard();
              "
            >
              CURRENT LEADERBOARD
              <span
                v-if="activeTab === 'current'"
                class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
              ></span>
            </div>

            <div
              class="relative pb-2 text-center text-white cursor-pointer"
              :class="{ 'text-blue-500': activeTab === 'previous' }"
              @click="
                activeTab = 'previous';
                changeLeaderboard();
              "
            >
              PREVIOUS LEADERBOARD
              <span
                v-if="activeTab === 'previous'"
                class="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500 transition-all duration-300"
              ></span>
            </div>
          </div>
          <div
            class="flex justify-center mt-8 text-xl mb:mb-24"
            style="font-family: Oxanium, sans-serif"
          >
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-left"
                  >
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center"
                  >
                    User
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-center"
                  >
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[15px] md:text-2xl font-semibold text-right"
                  >
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="top8.length == 0">
                  <td
                    colspan="4"
                    class="py-4 text-lg font-bold text-center text-white"
                  >
                    No data to show
                  </td>
                </tr>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in top8"
                  :key="index"
                >
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-semibold text-left"
                  >
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[13px] text-white font-semibold text-center"
                  >
                    {{ maskString(item.displayName) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-semibold text-center"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img
                        src="../assets/gold-coin.png"
                        class="w-4 mb-[1px] mr-0.5"
                      />
                      <span>{{ item.wagerAmount.toFixed(2) }}</span>
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[12px] text-white font-semibold text-right"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <img
                        src="../assets/gold-coin.png"
                        class="w-4 mb-[1px] mr-0.5"
                      />
                      <span>{{
                        activeTab == "current"
                          ? getRewardValue(index)
                          : getPrevRewardValue(index)
                      }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="loading == false"
          class="container flex flex-col justify-center w-full mt-8 border-t border-gray-600 lg:my-14"
          style="font-family: Oxanium, sans-serif"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

export default {
  data() {
    return {
      top3: [],
      top5: [],
      top8: [],

      days: "",
      hours: "",
      minutes: "",
      seconds: "",

      activeTab: "current",

      showTooltip1: false,
      showTooltip2: false,

      currentLeaderboard: [],
      previousLeaderboard: [],

      startDate: null,
      endDate: null,

      prevStartDate: null,
      prevEndDate: null,

      notFormattedEndDate: null,

      loading: false,

      currentPointsDistribution: JSON.parse(
        process.env.VUE_APP_CURRENT_POINTS_DISTRIBUTION
      ),
      prevPointsDistribution: JSON.parse(
        process.env.VUE_APP_PREV_POINTS_DISTRIBUTION
      ),
      leaderboardPrizes: JSON.parse(
        process.env.VUE_APP_CHICKENGG_LEADERBOARD_CURRENT_PRIZES
      ),
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;

      await axios
        .get(`${baseUrl}/api/leaderboard/chickengg`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        })
        .then((res) => {
          this.currentLeaderboard = res.data.leaderboard;

          this.startDate = moment(res.data.startDate)
            .utc()
            .format("MMMM DD, YYYY");
          this.endDate = moment(res.data.endDate).utc().format("MMMM DD, YYYY");

          this.notFormattedEndDate = res.data.endDate;
        });

      await axios
        .get(`${baseUrl}/api/leaderboard/chickengg?previous=true`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        })
        .then((rest) => {
          this.previousLeaderboard = rest.data.leaderboard;

          this.prevStartDate = moment(rest.data.startDate)
            .utc()
            .format("MMMM DD, YYYY");
          this.prevEndDate = moment(rest.data.endDate)
            .utc()
            .format("MMMM DD, YYYY");
        });

      this.changeLeaderboard();
      this.startTimer();

      this.loading = false;
    },
    async changeLeaderboard() {
      if (this.activeTab == "current") {
        const leaderboard = this.currentLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.top5 = leaderboard.slice(3, 8).map((item) => {
          return item;
        });

        this.top8 = leaderboard.slice(0, 8).map((item) => {
          return item;
        });
      } else {
        const leaderboard = this.previousLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.top5 = leaderboard.slice(3, 8).map((item) => {
          return item;
        });

        this.top8 = leaderboard.slice(0, 8).map((item) => {
          return item;
        });
      }
    },
    async getProfile(discordId, avatar) {
      const picUrl =
        "https://cdn.discordapp.com/avatars/" +
        discordId +
        "/" +
        avatar +
        ".png";
      return picUrl;
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      const prizes = JSON.parse(
        process.env.VUE_APP_CHICKENGG_LEADERBOARD_CURRENT_PRIZES
      );

      return prizes[rank];
    },
    getPrevRewardValue(rank) {
      const prizes = JSON.parse(
        process.env.VUE_APP_CHICKENGG_LEADERBOARD_PREV_PRIZES
      );

      return prizes[rank];
    },
    maskString(str) {
      if (!str || str.length < 2) {
        return str;
      }
      if (!str || str.length <= 4) {
        const start = str.slice(0, 1);
        const end = str.slice(-1);
        const middleAsterisks = "*".repeat(str.length - 2);

        return `${start}${middleAsterisks}${end}`;
      } else {
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = "*".repeat(str.length - 4);

        return `${start}${middleAsterisks}${end}`;
      }
    },
    startTimer() {
      const getNextTargetDate = () => {
        // const now = moment.utc();

        let targetDate = moment
          .utc(this.notFormattedEndDate)
          .subtract(1, "seconds");

        // if (now.isAfter(targetDate)) {
        //   targetDate.add(1, 'week');
        // }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowUtc = moment.utc();
        const distance = targetDate.diff(nowUtc);

        if (distance < 0) {
          // this.init();
          clearInterval(timerInterval);

          // targetDate = getNextTargetDate();
          // this.startTimer();
        } else {
          const duration = moment.duration(distance);
          this.days = Math.floor(duration.asDays());
          this.hours = duration.hours();
          this.minutes = duration.minutes();
          this.seconds = duration.seconds();
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.frame-bg {
  background-image: url("../assets/points-frame-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.mobile-frame-bg {
  background-image: url("../assets/points-mobile-frame-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
